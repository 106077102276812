<template>
  	<div>
    	<main class="container-fluid mt-0">
			<div class="text-center d-none d-md-block d-lg-block" style="position: absolute; top: 3vh; left: 44%; z-index: 1;">
				<router-link to="/"><img src="@/assets/images/logo.png" width="150px" /></router-link>
			</div>
			<div class="row mx-md-4 mt-5 mb-4 d-flex align-items-center">
				<div class="col-md-8 text-center d-none d-md-block d-lg-block">
					<img src="@/assets/images/img-login.png" width="70%" />
				</div>
				<div class="col-md-12 d-md-none d-lg-none text-center pb-3">
					<router-link to="/"><img src="@/assets/images/logo.png" width="150px" /></router-link>
				</div>
				<div class="col-md-4">
					<div class="card shadow border-primary round">
						<div class="card-body p-4">
							<div class="text-center py-2">
								<i class="far fa-user fa-6x text-primary"></i>
							</div>
							<form ref="formLogin" class="px-3 py-2" @submit.prevent="login">
								<h4 class="text-center">Masuk</h4>
								<hr class="mt-3 mb-3 w-75" />
								<label for="inlineFormInputGroupEmail" class="text-muted mb-1">Email</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-envelope text-primary"></i></div>
									</div>
									<input type="email" v-model="email" name="email" class="form-control" id="inlineFormInputGroupEmail" placeholder="Email" required />
								</div>
								<label for="inlineFormInputGroupKataSandi" class="text-muted mb-1">Kata Sandi</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-key text-primary"></i></div>
									</div>
									<input type="password" v-model="password" name="password" class="form-control pr-5" id="inlineFormInputGroupKataSandi" placeholder="Kata Sandi" required />
								</div>
								<span toggle="#inlineFormInputGroupKataSandi" @click="togglePassword" class="fa fa-lg fa-eye toggle-password text-primary"></span>
								<div class="text-center mt-4">
									<button type="submit" class="btn btn-primary w-100">Masuk</button>
									<hr class="mt-4 mb-3 w-75" />
									<p class="text-muted mb-0">Belum punya akun? <router-link to="/register" class="text-success text-decoration-none">Daftar</router-link></p>
									<hr class="w-25 my-1" />
									<p class="text-muted mb-0">Lupa Kata Sandi? <router-link to="/forgot-password" class="text-info text-decoration-none">Lupa</router-link></p>
								</div>
							</form>
						</div>
					</div>
					<div class="text-center py-3"><a href="#" @click="$router.push('/home')" class="text-secondary text-decoration-none">Kembali</a></div>
				</div>
			</div>
		</main>
  	</div>
</template>

<script>

export default {
  	data () {
 		return {
			email: '',
			password: ''
 		}
	},
	methods: {
		togglePassword: function() {
			$('span.toggle-password').toggleClass("fa-eye fa-eye-slash");
			let input = $($('span.toggle-password').attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			}
			else {
				input.attr("type", "password");
			}
		},
		login: function () {
			let formLogin = this.$refs.formLogin
			let formData = new FormData(formLogin)
        	this.$store.dispatch('login', formData)
       		.then(response => {
				this.email = ''
				this.password = ''
				this.$swal({
					title: 'Berhasil Masuk', 
					html: '<p>Selamat Datang '+ response +'</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				this.$router.push('/home')
			})
       		.catch(response => {
				// this.email = ''
				this.password = ''

				if(response === 'Password salah') {
					this.$swal({
						title: 'Oops!', 
						html: '<p>Email atau Kata Sandi salah</p>',
						icon: 'error',
						timer: 3000,
						showConfirmButton: false,
					});
				}
				else {
					this.$swal({
						title: 'Oops!', 
						html: `
							<p class="mb-1">Pengguna belum terdaftar</p>
							<p class="text-muted">Klik <a href="/register" class="text-info text-primary"><strong>di sini</strong></a> untuk mendaftar!</p>
						`,
						icon: 'error',
						timer: 7000,
						showConfirmButton: false,
					});
				}
			})
 		}
	},
	created() {
		$(document).scrollTop(0)
	}  
}
</script>